svg#icon #shape {
  fill: #242424;
}

svg#icon #letter {
  fill: #F7F4ED
}

svg#logo #letter {
  fill: #EF5747;
}

svg#logo #text {
  fill: #EF5747
}

/* TODO: Update when dark mode enabled */
@media (prefers-color-scheme: dark) {

  svg#icon #shape {
    fill: #F7F4ED;
  }

  svg#icon #letter {
    fill: #EF5747
  }

  svg#logo #letter {
    fill: #EF5747;
  }

  svg#logo #text {
    fill: #EF5747
  }
}